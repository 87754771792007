import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { withTheme } from '@material-ui/core/styles';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { has, get, filter, remove, last } from 'lodash';
import { CARE_JOURNAL_WHITEBOARD } from 'routes/constants';
import { isDigitalSharedDisplayRole } from 'utils/CareJournalUtils';
import { getPrimaryColor, ImageLazyLoader } from '../../../utils';
import { AbilityContext } from '../../../utils/ability/abilityContext';
import {
  PRODUCTION,
  PROD,
  HIDE_MODULES_BY_ROLES,
  CSS_ROLES,
} from '../../../utils/constants';

const handleSubmenu = active => {
  if (document && document.getElementsByClassName) {
    const contentEle = document.getElementsByClassName('s7t-content');
    if (!active && contentEle?.[0]) {
      contentEle[0].className = contentEle[0].className.replace(
        's7t-with-submenu',
        ''
      );
    } else if (
      contentEle?.[0] &&
      !contentEle[0].classList.contains('s7t-with-submenu')
    ) {
      contentEle[0].className = `${contentEle[0].className} s7t-with-submenu`;
    }
  }
};

const handleContentWindow = collapse => {
  if (document && document.getElementsByClassName) {
    const contentEle = document.getElementsByClassName('s7t-logged-content');
    const eleExpand = document.getElementsByClassName('s7t-sidemenu-expand');

    if (!eleExpand.length && contentEle?.[0]) {
      contentEle[0].className = `${contentEle[0].className} s7t-sidemenu-expand`;
    } else if (collapse && contentEle?.[0]) {
      contentEle[0].className = contentEle[0].className.replace(
        ' s7t-sidemenu-expand',
        ''
      );
    }
  }
};

class DesktopSidemenu extends Component {
  constructor(props) {
    super(props);
    const { sidemenuData } = this.props;
    this.state = {
      showSubmenu: false,
      collapse: true,
      selectedMenuItem: '',
      menuList: this.getSidemenuWithCondition(sidemenuData),
    };
    this.isCloseCollapse = false;
    this.getStyleForMenu = this.getStyleForMenu.bind(this);
    this.getMenuLink = this.getMenuLink.bind(this);
    this.toggleSideMenu = this.toggleSideMenu.bind(this);
    this.submenu = null;
    document.addEventListener(
      'click',
      e => {
        const ele = e || window.event;

        if (
          has(ele, 'srcElement.className') &&
          typeof ele.srcElement.className.indexOf === 'function' &&
          ele.srcElement.className.indexOf('s7t-menu-item') === -1 &&
          ele.srcElement.className.indexOf('w3-text w3-left-align') === -1 &&
          ele.srcElement.className.indexOf('s7t-side-menu-img-in-link') === -1
        ) {
          this.setState({ showSubmenu: false }, () => {
            handleSubmenu(false);
          });
        }
      },
      false
    );
  }

  getSidemenuWithCondition(sidemenu) {
    const { isParent } = this.props;
    let transformedMenuList = sidemenu;

    if (isParent) {
      transformedMenuList = filter(
        transformedMenuList,
        menu => menu.path !== 'settings'
      );
      const homeMenu = transformedMenuList.find(
        item => item.path === 'overview'
      );
      const index = transformedMenuList.indexOf(homeMenu);
      transformedMenuList.splice(index, 1);
    }
    return transformedMenuList;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { sidemenuData, isParent } = this.props;
    const { menuList } = this.state;
    const transformedMenuList = this.getSidemenuWithCondition(
      newProps.sidemenuData
    );
    if (
      sidemenuData.length !== get(newProps, 'sidemenuData.length') &&
      get(newProps, 'sidemenuData.length') &&
      !menuList.length &&
      isParent !== undefined
    ) {
      this.setState({
        // TODO: remove this logic once the page is ready
        menuList: transformedMenuList,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  getLogo() {
    const { theme } = this.props;
    let logo = '';
    if (get(theme, 'logo')) {
      logo = theme.logo;
    }

    return (
      <ListItem className="s7t-sidemenu-logo" key="logo">
        <ImageLazyLoader alt="logo" src={logo} />
      </ListItem>
    );
  }

  getStyleForMenu(path) {
    let actStyle = null;
    const {
      location: { pathname },
    } = this.props;

    const pathArr = pathname.split('/');
    const pathSplit = path != null && path.split('/');

    if (
      (pathArr && pathArr.includes(path)) ||
      (get(pathSplit, 'length') && pathArr.includes(last(pathSplit)))
    ) {
      actStyle = {
        color: getPrimaryColor(this.props),
        borderLeft: `5px solid ${getPrimaryColor(this.props)}`,
      };
    }
    return actStyle;
  }

  openWithNewTab = path => {
    const { history } = this.props;
    if (history?.location?.path === path) return;
    window.open(`/${path}`, '_blank');
  };

  // TODO: remove the temporary check for EP -> Finance menu once BE implement proper access
  getSubmenu(submenu, isEP) {
    const { context } = this;
    const {
      history,
      isFinance,
      getClassDecisionLabelVendor,
      hasEpReportsAccess,
      userRole,
      isCSS,
    } = this.props;

    const bannerAlert = get(process.env, 'REACT_APP_ENVIRONMENT', 'production');
    const hideSubMenus = HIDE_MODULES_BY_ROLES[userRole] || [];
    const closeCollapse = (e, path) => {
      e.stopPropagation();
      /**
       * Only open the Digital Shared Display Whiteboard in new tab
       * if the user is not a Digital Shared Display Role
       */
      if (
        `/${path}` === CARE_JOURNAL_WHITEBOARD &&
        !isDigitalSharedDisplayRole(userRole)
      ) {
        this.isCloseCollapse = true;
        this.setState(
          {
            collapse: true,
            showSubmenu: false,
          },
          () => {
            handleSubmenu(false);
            handleContentWindow(true);
          }
        );
        this.openWithNewTab(path);
      } else {
        history.push(`/${path}`);

        this.isCloseCollapse = true;

        const timeOut = 120;
        const promise = new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, timeOut);
        });

        promise.then(() => {
          this.setState(
            {
              collapse: true,
              showSubmenu: false,
            },
            () => {
              handleSubmenu(false);
              handleContentWindow(true);
            }
          );
        });
      }
    };
    this.submenu = (
      <div>
        <Paper
          style={{
            top:
              bannerAlert === PRODUCTION || bannerAlert === PROD ? 0 : '40px',
          }}
          elevation={2}
          className="s7t-submenu s7t-border-right"
        >
          {submenu.map(menuItem => {
            const actStyle = this.getStyleForMenu(menuItem.path);
            const isDisabled = !menuItem.active;

            if (hideSubMenus.includes(menuItem.label)) return null;

            if (
              isEP &&
              !context.can('approve', 'enrichment_management') &&
              !menuItem.label.includes('Finance') &&
              isFinance
            ) {
              return null;
            }

            if (
              isEP &&
              !hasEpReportsAccess &&
              menuItem.label.includes('Reports')
            ) {
              return null;
            }

            // hiding reports and vistor logs for CSS role
            if (
              isCSS &&
              (menuItem.label.includes('Reports') ||
                menuItem.label.includes('Visitor Logs'))
            ) {
              return null;
            }

            return (
              <ListItem
                key={`menu-${menuItem.text}-${menuItem.path}`}
                onClick={e => closeCollapse(e, menuItem.path)}
                alignItems="center"
                disabled={isDisabled}
                style={actStyle}
                className="s7t-menu-item"
                button
              >
                <img
                  width="23"
                  alt=""
                  src={menuItem.icon}
                  style={{ minWidth: '23px' }}
                />
                <span className="w3-text w3-center">
                  {getClassDecisionLabelVendor(menuItem.label, menuItem.path)}
                </span>
                {menuItem.submenu && (
                  <RightArrow
                    color="primary"
                    className="s7t-menu-arrow-right"
                  />
                )}
              </ListItem>
            );
          })}
        </Paper>
      </div>
    );
  }

  getMenuLink(menuItem) {
    const isDisabled = !menuItem.active;
    let navLink = !isDisabled ? menuItem.path : '#';
    const { showSubmenu, collapse, selectedMenuItem } = this.state;
    const { goToLink, schoolConfigObj } = this.props;
    const toggleByPassLogin = get(
      schoolConfigObj,
      'enable_by_pass_login_ep',
      '0'
    );
    if ((navLink || []).includes('enrichment') && toggleByPassLogin === '0') {
      return;
    }
    let menuItemFunc = () => {
      if (navLink === 'enrichment') {
        window.open(`${process.env.REACT_APP_EP_DOMAIN}/enrichment/programs`);
        return;
      }

      handleSubmenu(false);
      goToLink(navLink);
      this.setState({
        showSubmenu: false,
      });
      if (!collapse) {
        this.setState({ collapse: true }, () => {
          handleContentWindow(true);
        });
      }
    };

    let actStyle = null;
    actStyle = this.getStyleForMenu(menuItem.path);

    if (menuItem.submenu) {
      navLink = '#';

      menuItem.submenu.forEach(eachSubmenu => {
        const hasActiveSubmenu = this.getStyleForMenu(eachSubmenu.path);
        if (hasActiveSubmenu) {
          actStyle = this.getStyleForMenu(eachSubmenu.path);
        }
      });

      menuItemFunc = (ele, menuItemLabel) => {
        ele.preventDefault();
        const posDetails = ele.target.getBoundingClientRect();
        let topPosition = posDetails.top;

        const winHeight = window.innerHeight;
        const subMenuItemHeight = 80;
        const submenuHeight = subMenuItemHeight * menuItem.submenu.length;
        const isMenuItemOutOfBounds = posDetails.height > winHeight;
        const isSubmenuOutOfBounds = submenuHeight + topPosition > winHeight;

        let shouldShowSubMenu = !showSubmenu;
        if (showSubmenu && menuItemLabel !== selectedMenuItem) {
          shouldShowSubMenu = true;
        }
        const hasShowSubMenuChanged = shouldShowSubMenu !== showSubmenu;

        if (isMenuItemOutOfBounds || isSubmenuOutOfBounds) {
          topPosition = winHeight - submenuHeight;
        }

        if (submenuHeight > winHeight) {
          topPosition = 0;
        }

        this.getSubmenu(
          menuItem.submenu,
          menuItem.label === 'Enrichment Programme'
        );

        this.setState(
          {
            collapse: shouldShowSubMenu ? false : this.isCloseCollapse,
            selectedMenuItem: menuItemLabel,
          },
          () => {
            if (collapse) {
              handleContentWindow(true);
            } else {
              handleContentWindow(collapse);
            }
            this.isCloseCollapse = false;
          }
        );
        const timeOut = collapse ? 120 : 0;
        const promise = new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, timeOut);
        });

        promise.then(() => {
          this.setState(
            {
              showSubmenu: shouldShowSubMenu,
            },
            () => {
              if (hasShowSubMenuChanged) {
                handleSubmenu(shouldShowSubMenu);
              }
            }
          );
        });
      };
    }

    return (
      <ListItem
        key={menuItem.label}
        onClick={e => menuItemFunc(e, menuItem.label)}
        alignItems="center"
        disabled={isDisabled}
        style={actStyle}
        className="s7t-menu-item"
        button
      >
        {showSubmenu &&
          menuItem.submenu &&
          menuItem.label === selectedMenuItem &&
          this.submenu}
        <ImageLazyLoader
          width="23"
          src={menuItem.icon}
          alt={`${menuItem.label} icon`}
          className="s7t-side-menu-img-in-link"
        />
        <span className="w3-text w3-center">{menuItem.label}</span>
        {menuItem.submenu && (
          <RightArrow color="primary" className="s7t-menu-arrow-right" />
        )}
      </ListItem>
    );
  }

  toggleSideMenu() {
    const { collapse, showSubmenu } = this.state;

    this.setState(
      {
        showSubmenu: !collapse ? false : showSubmenu,
        collapse: !collapse,
      },
      () => {
        const { collapse, showSubmenu } = this.state;
        handleSubmenu(showSubmenu);
        handleContentWindow(collapse);
      }
    );
  }

  render() {
    const { collapse, menuList } = this.state;
    const { theme, getLogo, history } = this.props;
    const bannerAlert = get(process.env, 'REACT_APP_ENVIRONMENT', 'production');
    const mainArrowColor = theme.palette.primary.main;
    const buttonStyle = {
      background: collapse ? mainArrowColor : '#ffffff',
      border: collapse ? 'none' : `2px solid ${mainArrowColor}`,
      color: collapse ? '#ffffff' : mainArrowColor,
    };
    if (process.env.REACT_APP_ENRICHMENT_PLATFORM === 'false') {
      remove(menuList, ele => {
        return (
          ele.path === 'enrichment/payment' ||
          ele.path === 'enrichment/programs'
        );
      });
    }
    return (
      <Drawer variant="persistent" anchor="left" open>
        <Divider />
        <List
          disablePadding
          className={collapse ? 's7t-menu-list' : 's7t-menu-list expand'}
          style={{
            marginTop:
              bannerAlert === PRODUCTION || bannerAlert === PROD
                ? '0px'
                : '40px',
          }}
        >
          <ListItem
            className="s7t-sidemenu-logo"
            key="logo"
            onClick={() => history.push(`/overview`)}
          >
            <img alt="logo" src={getLogo()} />
          </ListItem>
          {menuList.map((menuItem, idx) => this.getMenuLink(menuItem, idx))}
        </List>
        <div
          onClick={this.toggleSideMenu}
          className="s7t-sidemenu-action-button"
          style={buttonStyle}
        >
          <span>
            {collapse ? (
              <ArrowForward fontSize="small" />
            ) : (
              <ArrowBack fontSize="small" />
            )}
          </span>
        </div>
      </Drawer>
    );
  }
}

DesktopSidemenu.contextType = AbilityContext;

DesktopSidemenu.defaultProps = {
  sidemenuData: [],
};

DesktopSidemenu.propTypes = {
  sidemenuData: PropTypes.arrayOf(PropTypes.shape),
};

const mapStateToProps = state => ({
  sidemenuData: get(state, 'sidemenu.data'),
  isParent: !get(
    state,
    'userDetails.data.userRoleRelations.data[0].role.isStaff'
  ),
  isTeacher:
    !get(state, 'userDetails.data.isHQStaff') &&
    !get(state, 'userDetails.data.hasCentreScopeAccess'),
  isFinance:
    get(state, 'userDetails.data.userRoleRelations.data[0].role.label') ===
    'Finance',
  userRole: get(state, 'userDetails.data.userRoleRelations.data[0].role.name'),
  isCSS: CSS_ROLES.includes(
    get(state, 'userDetails.data.userRoleRelations.data[0].role.label')
  ),
  schoolConfigObj: get(state, 'schoolConfig.list.asObject', {}),
});

const mapDispatchToProps = {};

export default withTheme(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DesktopSidemenu))
);
