/* eslint-disable import/prefer-default-export */
import { get } from 'lodash';
import { DIGITAL_SHARED_DISPLAY_ROLE } from './constants';

/**
 * Check if the user role is the Digital Shared Display Role.
 * @param {Object} userRole - The user role object.
 * @returns {boolean} - True if the user role is the Digital Shared Display Role, false otherwise.
 */
export const isDigitalSharedDisplayRole = userRole => {
  return get(userRole, 'name') === DIGITAL_SHARED_DISPLAY_ROLE;
};
